/* ScrollToTopButton.css */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.scroll-to-top button {
    background-color: rgba(9, 28, 27, 0.79);
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 20px;
    transition: opacity 0.3s, visibility 0.3s;
}

.scroll-to-top button:hover {
    background-color: rgba(10, 30, 29, .8);
}
