.advantage {
    width: 325px;
    height: 184px;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    scale: 0.9;
}

.atropos-highlight {
    opacity: 0 !important;
}

.advantage_background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: .5rem;
    background-size: cover;
    background-position: center;
    filter: blur(4px);
    z-index: 1;
    opacity: 0.05;
}

.advantage_content {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    text-align: center;
    padding: 20px;
}

.advantage_content p {
    --tw-text-opacity: 1;
    font-weight: 700;
    color: hsl(168 53.4% 43.97% / var(--tw-text-opacity));
    margin: 0;
}

.advantage_content img {
    border-radius: .5rem;
    width: 70px;
    height: auto;
}

.advantage:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(10, 30, 29);
    border-radius: .5rem;
    z-index: 1;
}
/*
developed by the JPEG committee
progressive decoding for web experience
lossless jpeg transcoding
photographic & synthetic images
embraces wide gamut / HDR and 32 bit depth
fast software encoding and decoding
full of smartphone features like overlays
WIP support for animated frames
FOSS and royalty-free license
*/