.what_is {
    padding: 0px 5px;
    background: rgb(13, 41, 41);
    border-radius: 5px;
    margin-left: 5px;
    position: relative;
}

.what_is_hovered {
    position: absolute;
    display: flex;
    top: 0;
    left: 120%;
    font-size: 0.875rem;
    width: 180px;
    padding: .5rem;
    background: hsl(179 51% 7.69% / var(--tw-bg-opacity));
    border: 2px solid rgb(13, 41, 41);
    border-radius: 1rem;
    z-index: 100;
}
