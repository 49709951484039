/*markers*/

article {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 736px;
    width: 90%;
    justify-content: center;
    text-align: left;
}

nav.content_table {
    padding: 1.5rem;
    background-color: rgba(10, 30, 29, .8);
    border-radius: .5rem;
    margin-top: 1.5rem;
    margin-bottom: .5rem;
    display: flex;
    flex-direction: column;
}

.content_ol {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: auto;
}

nav.content_table > h4 {
    display: flex;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.num {
    margin-right: .5rem;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: .5rem;
    text-align: right;
    --tw-text-opacity: 1;
    color: rgb(40 123 122 / var(--tw-text-opacity));
    font-size: 80%;
    content: counter(step-counter);
}

.content_ol li {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.green {
    text-decoration: none;
    font-size: 1.25rem;
    cursor: pointer;
    --tw-text-opacity: 1;
    color: hsl(168 53.4% 43.97% / var(--tw-text-opacity));
}

.main, main {
    font-size: 3rem;
    margin-top: 6.5rem;
    font-weight: 700;
}

.umain, umain {
    margin-bottom: 1rem;
    color: hsla(0, 0%, 100%, .85);
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
}

.ugreen {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.25rem;
    cursor: pointer;
    --tw-text-opacity: 1;
    color: hsl(168 53.4% 43.97% / var(--tw-text-opacity));
}

.related_articles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.img1 {
    width: 100%;
}

div.code {
    background: rgb(12, 24, 24);
    width: calc(100%);
    font-size: 16px;
    font-weight: 300;
    border-radius: 5px;
}

div.code > or {
    color: rgb(183, 137, 116);
}

div.code > lag {
    color: rgb(127, 182, 208);
}

div.code > bl {
    color: rgb(78, 143, 195);
}

.code1 {
    width: 40%;
}

.code2 {
    width: 60%;
}

.code3 {
    width: 40%;
}

.code4 {
    width: 70%;
}

.code5 {
    width: 100%;
}

.green2 {
    font-size: 12px;
    color: #34ab93;
    margin: 0;
}

.words {
    display: flex;
    width: calc(100% - 25px);
    flex-direction: column;
    padding: 10px;
    background: #0a1e1d;
    border-left: 5px solid #0d2929;
}

@media screen and (max-width: 1000px) {
    .heading > .heading_content {
        margin-left: 50px;
    }
}
@media screen and (max-width: 700px) {
    .related_articles {
        grid-template-columns: repeat(2, 1fr)
    }
}
@media screen and (max-width: 650px) {
    .related_articles {
        grid-template-columns: repeat(1, 1fr)
    }
}
@media screen and (max-width: 500px) {
    .heading > .heading_content > h1{
        font-size: 2.5rem;
        max-width: 80%
    }
    .main {
        font-size: 1.75rem;
    }
    .umain {
        font-size: 1rem
    }
}