body {
  margin: 0 auto;
  font-family: Poppins;
  overflow-x: hidden;
  --tw-bg-opacity: 1;
  background-color: hsl(179 51% 4.69% / var(--tw-bg-opacity));
  color: white;
  font-size: 1.25rem;
}

.sitemapping {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 80px;
  align-items: center;
  margin-bottom: 80px;
}

.sitemapping a {
  color: white;
}

/*adaptation*/

@media screen and (max-width: 1120px) {
  .search_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .black  {
    display: none !important;
  }

}

@media screen and (max-width: 900px) {
  .summary_content {
    max-width: 95% !important;
  }

  .search_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .gradient {
    width: 40vw !important;
    overflow-x: hidden;
    height: 50vh !important;
  }

  a > h1 {
    line-height: normal;
  }

  .converter {
    background: unset !important;
    padding: 0 !important;
    width: auto !important;
  }

  #settings > .not {
    display: none;
  }

  #form-file-upload {
    min-width: 90vw !important;
    margin: 0 auto !important;
    width: unset !important;
  }

  .convert_button {
    width: calc(90% - 6rem) !important;
  }
}

@media screen and (max-width: 705px) {
  #footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .column {
    width: auto !important;
  }
  .search_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .convert_text {
    text-align: center !important;
  }

  #convert > div:nth-child(2) > p {
    flex-direction: column;
    text-align: center;
  }

  .options {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 650px) {
  .convert_button {
    padding: .5rem !important;
    width: calc(95% - 1rem) !important;
  }

  #summary > h2,
  #summary > div > p{
    max-width: 95%;
  }

  .advantage {
    width: 95vw !important;
  }

  #header > div > div:nth-child(2) > a,
  #header > div > div:nth-child(1) > p:nth-child(2)
  {
    display: none;
  }

  .search_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .search_heading {
    margin: 0 auto;
    min-width: 94% !important;
    max-width: 94% !important;
  }

  .tutorial, .article {
    min-width: 90% !important;
    max-width: 90% !important;
    margin: 0 auto;
  }
}