#blog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90vw;
}

.search {
    position: relative;
    max-width: 1590px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.search input {
    margin-top: 10vh;
    background-color: rgba(10, 30, 29, .8);
    --tw-border-opacity: 1;
    border: 1px solid hsl(179 51% 7.69% / var(--tw-border-opacity));
    outline: 2px solid transparent;
    padding: 1rem;
    font-family: inherit;
    border-radius: .5rem;
    width: calc(100% - 2rem);
    font-size: 20px;
    color: white;
}

.support {
    font-size: 32px;
    margin: 10px 0;
}

.subheading {
    font-size: 20px;
    margin: 10px 0;
    font-weight: 600;
}

.options {
    width: 100%;
    display: flex;
}

.option{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: .5rem;
    padding-right: .5rem;
    --tw-text-opacity: 1;
    color: rgb(176 176 176 / var(--tw-text-opacity));
    margin-top: .5rem;
    margin-right: .5rem;
    --tw-bg-opacity: 1;
    background-color: hsl(179 51% 7.69% / var(--tw-bg-opacity));
    border-radius: .25rem;
}

.search_heading {
    margin-top: 2.5rem;
    text-align: left;
    display: flex;
    width: 100%;
    font-size: 32px;
    font-weight: 700;
    max-width: 1590px;
}

.search_container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.line_between{
    margin-bottom: 5vh;
    width: 50%;
    height: 1px;
    background: #ffffff;
    opacity: 0.1;
    margin-top: 50px;
}
