#convert {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 180px;
}

.convert_text {
    font-family: Patua One;
    font-size: 3rem;
    margin: 0;
    z-index: 10;
}

#convert > div > p {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.black {
    border-radius: 10px;
    padding: 5px;
    --tw-bg-opacity: 1;
    background-color: hsl(179 51% 10.69% / var(--tw-bg-opacity));
    font-size: .875rem;
    cursor: pointer;
}

.how {
    display: none;
}

.black:hover .how {
    --tw-bg-opacity: 1;
    background-color: hsl(179 51% 7.69% / var(--tw-bg-opacity));
    padding: .5rem;
    text-align: left;
    line-height: 1.375;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    font-size: .875rem;
    position: absolute;
    z-index: 1;
    width: 200px;
    border-radius: 1rem;
    border: 1px solid hsl(179 51% 10.69% / var(--tw-border-opacity));
    --tw-border-opacity: 1;
    top: 65px;
    left: 92%;
    display: block;
}

/* converter */

.converter {
    padding: 1.6rem;
    height: auto;
    background: rgb(255,255,255,0.1);
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 700px;
}

.converter_content {
    background: white;
    border-radius: 2rem;
    transition: 0.5s all;
    scale: 1
}

.converter_content:hover {
    scale: 0.98
}
