#footer {
    background: rgba(10,30,29,.8);
    width: calc(100% - 1rem);
    padding-top: 1.5rem;
    padding-bottom: 6.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: flex;
    align-items: flex-start;
}

.column {
    display: flex;
    flex-direction: column;
    width: 25vw;
    padding: 0 5px;
}

.column:nth-child(1) a {
    font-size: 2rem;
    color: white;
    font-weight: 700;
    text-decoration: none;
    margin: 0;
}
.column:nth-child(1) p {
    --tw-text-opacity: 0.5;
    color: rgb(255 255 255 / var(--tw-text-opacity));
    margin: 0;
}

.column a {
    color: #848d8c;
    text-decoration: none;
}

.column a:hover {
    color: white;
}