#header {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    border-bottom: 0.4px solid rgb(255, 255, 255, 0.2);
    z-index: 20;
}

.header {
    width: 98%;
    display: flex;
    justify-content: space-between;
}

.header > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.header > div > a > img {
    width: 25px;
    height: auto;
}

.header > div > a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header > div > button {
    font-weight: bold;
    background-color: rgba(13,41,41,.8);
    border: 0;
    border-radius: .5rem;
    height: 70%;
    color: white;
    font-size: 1.25rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    padding-right: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.header > div > button > a {
    text-decoration: none;
    color: inherit;
}

.header > div > button > a > svg {
    width: 15px;
}

.header > div > p > a {
    text-decoration: none;
    color: white;
}