.heading {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
    width: 100vw;
    background: radial-gradient(circle at center left, #37aaa8, transparent 50%), radial-gradient(circle at 0 0, #9f9f07, transparent 25%), radial-gradient(circle at 20% 60%, #37aaa8, transparent 50%), radial-gradient(circle at center right, #34ac94, transparent 50%), radial-gradient(circle at 60% 60%, #2eaf6f, transparent 25%), radial-gradient(circle at bottom center, #34ac94, transparent 75%), radial-gradient(circle at 80% 40%, #2eaf6f, transparent 75%), radial-gradient(circle at center center, #000, #000 100%);
}

.heading > .heading_content > h1 {
    font-family: Patua One, serif;
    font-size: 3.75rem;
}
.heading > .heading_content {
    margin-left: 100px;
}


