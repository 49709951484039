#summary {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
}

#summary h2 {
    font-size: 2rem;
}

.summary_content {
    max-width: 50%;
}

.summary_content p {
    font-size: 1.25rem;
}

.convert_button {
    margin-top: 70px;
    background-image: radial-gradient(circle at center left,#37aaa8,transparent 50%),radial-gradient(circle at 0 0,#9f9f07,transparent 25%),radial-gradient(circle at 20% 60%,#37aaa8,transparent 50%),radial-gradient(circle at center right,#34ac94,transparent 50%),radial-gradient(circle at 60% 60%,#2eaf6f,transparent 25%),radial-gradient(circle at bottom center,#34ac94,transparent 75%),radial-gradient(circle at 80% 40%,#2eaf6f,transparent 75%),radial-gradient(circle at center center,#000,#000 100%);
    padding: 3rem;
    border-radius: .5rem;
    line-height: 1.5rem;
    color: white;
    text-decoration: none;
    text-align: left;
    scale: 1;
    transition: 0.5s all;
    position: relative;
}

.convert_button:hover {
    scale: 1.03;
}

.glow {
    position: absolute;
    background-image: radial-gradient(circle at center left,#37aaa8,transparent 50%),radial-gradient(circle at 0 0,#9f9f07,transparent 25%),radial-gradient(circle at 20% 60%,#37aaa8,transparent 50%),radial-gradient(circle at center right,#34ac94,transparent 50%),radial-gradient(circle at 60% 60%,#2eaf6f,transparent 25%),radial-gradient(circle at bottom center,#34ac94,transparent 75%),radial-gradient(circle at 80% 40%,#2eaf6f,transparent 75%),radial-gradient(circle at center center,#000,#000 100%);
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    filter: blur(15px);
}