#form-file-upload {
    height: 18rem;
    width: 700px;
    max-width: 100%;
    text-align: center;
    position: relative;
    scale: 1;
    transition: 0.5s all;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 2rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
    transition: 0.2s all;
}

#label-file-upload.drag-active {
    border-style: dashed;
    border-color: #385351;
    background-color: rgba(95, 162, 154, 0.4);
}

.upload-button {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    border: 0;
    margin-bottom: 30px;
    background-image: radial-gradient(circle at center left, #37aaa8, transparent 50%), radial-gradient(circle at 0 0, #9f9f07, transparent 25%), radial-gradient(circle at 20% 60%, #37aaa8, transparent 50%), radial-gradient(circle at center right, #34ac94, transparent 50%), radial-gradient(circle at 60% 60%, #2eaf6f, transparent 25%), radial-gradient(circle at bottom center, #34ac94, transparent 75%), radial-gradient(circle at 80% 40%, #2eaf6f, transparent 75%), radial-gradient(circle at center center, #000, #000 100%);
    animation: rotate 30s cubic-bezier(0.8, 0.2, 0.2, 0.8) alternate infinite;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

#text > p:nth-child(2) {
    color: rgba(47, 146, 144, .8);
    font-size: 1.25rem;
    margin: 0;
}

#text > p:nth-child(3) {
    color: #282c34;
    font-size: 0.875rem;
    margin: 0;
}

#text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

#text img {
    position: absolute;
    top: 37px
}

#settings {
    display: flex;
    position: relative;
}

#settings > .not {
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 11;
    top: 20px;
    fill: black;
    right: 20px;
    transition: 0.5s all;
    animation: rotate-360 0.1s ease-in-out;
}

#settings > .rotated {
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 11;
    top: 20px;
    fill: black;
    right: 20px;
    transition: 0.5s all;
    animation: rotate360 0.1s ease-in-out;
}

@keyframes rotate360 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotate-360 {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.settings_menu {
    flex-direction: column;
    display: flex;
    text-align: left;
    position: absolute;
    right: -430px;
    background: rgb(0, 0, 0, 0.75);
    width: 360px;
    height: 530px;
    padding: 20px;
    border-radius: 8px;
    top: 160px;
}

.settings_menu > h2,
.settings_menu > p {
    margin: 0;
}

.settings_menu > p {
    font-size: 0.875rem
}

.download {
    font-size: 0.875rem;
    background: rgb(0, 0, 0, 0.5);
    width: 90%;
    padding: 0 5%;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    border-radius: 8px;
}

.download > a {
    color: white;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 18px;
}

div.green {
    background: hsl(179 51% 7.69%/var(--tw-bg-opacity));
    border-radius: 5px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: calc(100% - 15px);
}

.inline {
    display: flex;
    gap: 10px;
    align-items: center;
}

.exp {
    font-weight: 600;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 8px;
    --tw-bg-opacity: 1;
    background-color: hsl(179 51% 10.69% / var(--tw-bg-opacity));
}

/*settings*/
.setting-group {
    display: flex;
    flex-direction: column;

}

input[type=range] {
    max-width: 100px;
}

.group {
    background: rgb(10, 30, 29);
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 5px;
}

.ranged {
    display: flex;
    gap: 10px;
    font-size: 16px;
}

