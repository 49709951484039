.sk-cube-grid {
    width: 40px;
    height: 40px;
    margin: 0 auto
}

.sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: #fff;
    float: left;
    animation: sk-cubeGridScaleDelay 1.3s ease-in-out infinite
}

.sk-cube-grid .sk-cube1 {
    animation-delay: .2s;
    --tw-bg-opacity: 1;
    background-color: rgb(237 249 249/var(--tw-bg-opacity))
}

.sk-cube-grid .sk-cube2 {
    animation-delay: .3s;
    --tw-bg-opacity: 1;
    background-color: rgb(181 231 230/var(--tw-bg-opacity))
}

.sk-cube-grid .sk-cube3 {
    animation-delay: .4s;
    --tw-bg-opacity: 1;
    background-color: rgb(125 213 211/var(--tw-bg-opacity))
}

.sk-cube-grid .sk-cube4 {
    animation-delay: .1s;
    --tw-bg-opacity: 1;
    background-color: rgb(67 194 192/var(--tw-bg-opacity))
}

.sk-cube-grid .sk-cube5 {
    animation-delay: .2s;
    --tw-bg-opacity: 1;
    background-color: rgb(55 170 168/var(--tw-bg-opacity))
}

.sk-cube-grid .sk-cube6 {
    animation-delay: .3s;
    --tw-bg-opacity: 1;
    background-color: rgb(47 146 144/var(--tw-bg-opacity))
}

.sk-cube-grid .sk-cube7 {
    animation-delay: 0s;
    --tw-bg-opacity: 1;
    background-color: rgb(40 123 122/var(--tw-bg-opacity))
}

.sk-cube-grid .sk-cube8 {
    animation-delay: .1s;
    --tw-bg-opacity: 1;
    background-color: rgb(31 96 95/var(--tw-bg-opacity))
}

.sk-cube-grid .sk-cube9 {
    animation-delay: .2s;
    --tw-bg-opacity: 1;
    background-color: rgb(21 65 64/var(--tw-bg-opacity))
}

@keyframes sk-cubeGridScaleDelay {
    0%,70%,to {
        transform: scaleX(1)
    }

    35% {
        transform: scale3D(0,0,1)
    }
}

