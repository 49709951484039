#home {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
}

/*!* -------------------- *!*/
/*!* BACKGROUND ANIMATION *!*/


a.restored_link {
    --tw-text-opacity: 0.5 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
    margin: 0;
    font-weight: 400 !important;
    font-family: Poppins !important;
}

#footer > div:nth-child(1) > p:nth-child(3),
#footer > div:nth-child(1) > a:nth-child(4) {
    font-size: 1rem !important;
}

.gradient {
    z-index: -10;
    width: 60vw;
    height: 70vh;
    position: absolute;
    margin: 0 auto;
    top: 40vh;
    filter: blur(100px);
    background-image: radial-gradient(circle at center left, #37aaa8, transparent 50%), radial-gradient(circle at 0 0, #9f9f07, transparent 25%), radial-gradient(circle at 20% 60%, #37aaa8, transparent 50%), radial-gradient(circle at center right, #34ac94, transparent 50%), radial-gradient(circle at 60% 60%, #2eaf6f, transparent 25%), radial-gradient(circle at bottom center, #34ac94, transparent 75%), radial-gradient(circle at 80% 40%, #2eaf6f, transparent 75%), radial-gradient(circle at center center, #000, #000 100%);
    animation: rotate 10s cubic-bezier(0.8, 0.2, 0.2, 0.8) alternate infinite;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    position: absolute;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.bg-gradient {
}
