.faq_component {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px
}

.links_to_faq {
    padding: 0;
    margin: 0;
}

.link_to_faq {
    padding-left: .25rem;
    padding-right: .25rem;
    background-color: rgba(46, 175, 111, .1);
    border-radius: .5rem;
    line-height: normal;
    word-break: break-word;
    font-size: .875rem;
    color: #34ab93;
    text-decoration: none;
}

.faq_component_h5 {
    font-weight: 700;
    padding-top: .25rem;
    padding-bottom: .25rem;
    border-radius: .5rem;
    display: inline-block;
    --tw-bg-opacity: 1;
    background-color: hsl(179 51% 7.69% / var(--tw-bg-opacity));
    font-size: .875rem;
    line-height: normal;
    padding-left: .5rem;
    padding-right: .5rem;
    margin: 0;
}