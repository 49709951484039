#advantages {
    margin-top: 30vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.line {
    width: 50%;
    height: 1px;
    background: #ffffff;
    opacity: 0.1;
}

.articles-selected,
.releases-selected,
.tutorials-selected,

.browser-selected,
.cdn-selected,
.coding-selected,
.graphics-selected,
.social-selected,
.os-selected,
.mail-selected,
.cms-selected,

.partial-selected,
.full-selected,
.no-selected {
    --tw-text-opacity: 1 !important;
    color: rgb(55 170 168 / var(--tw-text-opacity)) !important;
    background-color: rgba(46, 175, 111, .1) !important;
}


@media (max-width: 500px) {
    .atropos-rotate-touch {
        touch-action: unset !important;
    }
}