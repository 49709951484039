.article {
    padding: 1rem;
    border-radius: 15px;
    background: rgb(10, 30, 29);
    max-width: calc(310px - 2rem);
    min-height: 134px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    position: relative;
    text-decoration: none;
}

.article h2 {
    font-size: 1.25rem;
    font-weight: 700;
    color: white;
    margin: 0;
}

.article p {
    font-size: .875rem;
    color: white;
    margin: 0;
}

.article::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    background: linear-gradient(90deg, rgb(117, 208, 105) 0%, rgb(62, 180, 173) 35%, rgb(51, 173, 141) 100%);
    border-radius: 10px;
    filter: blur(5px);
    transition: all 0.3s ease;
    pointer-events: none;
    opacity: 0;
    z-index: -1;
}

.article:hover::before {
    opacity: 5;
}